<template>
  <div class="p-3">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div class="mt-3">
        <b-form-radio-group v-model="formInput.is_goal" @change="setGoal">
          <b-row>
            <b-col><b-form-radio :value="0">No Goal</b-form-radio></b-col>
            <b-col><b-form-radio :value="1">Goal</b-form-radio></b-col>
          </b-row>
        </b-form-radio-group>
      </div>
      <hr />
      <div v-if="formInput.is_goal == 1" class="mt-2">
        <b-row class="align-items-center">
          <b-col md="12"
            ><div class="main-label">
              Action <span class="text-danger">*</span>
            </div></b-col
          >
          <b-col md="4"> A Customer Perform Event</b-col>

          <b-col md="3">
            <InputSelect
              title=""
              name="perform-event"
              v-model="formInput.event_type_id"
              v-bind:options="[{ id: 6, text: 'Purchase' }]"
              valueField="id"
              textField="text"
              :v="$v.formInput.event_type_id"
              :isValidate="$v.formInput.event_type_id.$error"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled>
                  --- Select Perform Event ---
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <b-col cols="12" class="px-3">
            <b-row
              no-gutters
              class="header-filter mx-0 align-items-center row-gap-lg p-3"
            >
              <b-col cols="12">
                It Counts as a conversion when, the customer acheieves the gaol,
                when perform event within:
              </b-col>
              <b-col cols="4" xl="2" class="d-inline-flex align-items-center">
                <InputText
                  textFloat=""
                  placeholder="0"
                  class="mb-0"
                  type="number"
                  v-model="formInput.value"
                  name="perform-days"
                  oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                  :isValidate="$v.formInput.value.$error"
                />
                <span class="mx-2">Days</span>
              </b-col>
              <b-col cols="5" xl="3"> After Join Campaign</b-col>
              <b-col
                class="text-danger"
                cols="12"
                v-if="$v.formInput.value.$error"
              >
                Please input value.
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  components: {
    OtherLoading,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
  validations: {
    formInput: {
      event_type_id: {
        required: requiredIf(function (item) {
          return item.is_goal === 1 && item.event_type_id == 0;
        }),
      },
      value: {
        required: requiredIf(function (item) {
          return item.is_goal === 1;
        }),
        minValue: minValue(0),
      },
    },
  },
  created() {
    if (this.$route.params.id != 0) this.formInput = { ...this.form.goal };
  },
  data() {
    return {
      isShowSidebar: false,
      condition_type_id: 0,
      noPleaseSelect: true,
      //   isSelected: this.form.is_goal,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      filename: "",
      fileBase64: "",
      isLoading: false,
      type: 0,
      formInput: {
        form_id: Number(this.$route.params.id),
        event_type_id: 0,
        is_goal: 0,
        value: 0,
      },
    };
  },

  methods: {
    show() {
      this.$v.$reset();
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submit() {
      // $emit('settingCondition', formInput)
      this.$v.$touch();
      if (this.$v.formInput.$error) return null;

      // this.$emit("submit", this.formInput);

      return this.formInput;
    },
    setGoal(val) {
      if (val == 0) {
        this.formInput = {
          id: this.formInput.id || 0,
          form_id: Number(this.$route.params.id),
          event_type_id: 0,
          is_goal: 0,
          value: 0,
        };
      } else {
        this.formInput.value = "";
        this.formInput.event_type_id = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  color: var(--font-color);
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
  padding: 2px 10px;
}
</style>
